import { states, transitions } from '../transactions/transactionProcessBooking';

export const DEFAULT_CONSTANTS = {
  BANNER_IMAGE_CATEGORIES:
    'https://sharetribe-assets.imgix.net/65860685-5fc1-4a52-939a-9cb38eda2e94/raw/8f/f539ebe9571878b2c5ffaccf05c8488345d860?auto=format&fit=clip&h=2400&w=2400&s=6a62d48abde9baa0ce6c4d11bba31f8b',
  CURRENCY_SYMBOL: '$',
  LINE_ITEM_SECURITY_DEPOSIT: 'line-item/security-deposit',
};

export const PRICE_KEYS = ['perDayPrice', 'perWeekPrice', 'perMonthPrice'];

export const EXTEND_BOOKING_STATUS = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  REQUESTED: 'requested',
};

export const ENUMS = {
  TRAILER_TYPE: 'trailerType',
}
export const PRICE_CODES = {
  DAY: 'day',
  PER_WEEK: 'perWeek',
  PER_MONTH: 'perMonth',
};

export const StatusCode = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
});

export const DashboadPageNavOption = Object.freeze({
  TRANSACTIONS_DETAILS: 'transactions-details',
});

export const Environment = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
});

export const TransactionDetailsPanelOption = Object.freeze({
  TRANSACTION_ID: 'transactionId',
  LISTING_TITLE: 'listingTitle',
  GUEST_NAME: 'guestName',
  RESERVATION_DATE: 'reservationDate',
  NIGHTS_BOOKED: 'nightsBooked',
  CUSTOMER_PAID_AMOUNT: 'customerPaidAmount',
  LAST_TRANSITION: 'lastTransition',
  TRANSITIONS: 'transitions',
  PAYOUT_DATE: 'payoutDate',
});

export const transitionAndStatusArr = [
  { lastTransition: 'transition/accept', label: 'Accepted' },
  { lastTransition: 'transition/decline', label: 'Declined' },
  { lastTransition: 'transition/expire', label: 'Expired' },
  { lastTransition: 'transition/cancel', label: 'Canceled' },
  { lastTransition: 'transition/complete', label: 'Completed' },
  { lastTransition: 'transition/refund-security-deposit', label: 'Refund security deposit' },
  { lastTransition: 'transition/request-damage-claim', label: 'Request damage claim' },
  { lastTransition: 'transition/accept-damage-claim', label: 'Accept damage claim' },
  {
    lastTransition: 'transition/decline-damage-claim-full-refund-to-customer',
    label: 'Decline damage claim',
  },
];

export const statesArr = [
  { value: 'inquiry', label: 'Inquiry' },
  { value: 'preauthorized', label: 'Requested bookings' },
  { value: 'declined', label: 'Declined' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'expired', label: 'Expired' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'provider-signed', label: 'Provider Signed' },
  { value: 'customer-signed', label: 'Customer Signed' },
  { value: 'booking-extended', label: 'Booking Extended' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'refund', label: 'Refund' },
  { value: 'refund-requested', label: 'Refund Requested' },
  { value: 'reviewed', label: 'Reviewed' },
];

export const dashBoardStateTransitionMap = {
  [states.INQUIRY]: {
    t1: transitions.INQUIRE,
  },
  [states.PREAUTHORIZED]: {
    t1: transitions.CONFIRM_PAYMENT,
  },
  [states.DECLINED]: {
    t1: transitions.DECLINE,
    t2: transitions.DECLINE_BY_CUSTOMER,
    t3: transitions.OPERATOR_DECLINE,
  },
  [states.ACCEPTED]: {
    t1: transitions.ACCEPT,
    t2: transitions.OPERATOR_ACCEPT,
  },
  [states.EXPIRED]: {
    t1: transitions.EXPIRE,
  },
  [states.CANCELED]: {
    t1: transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED,
    t2: transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED,
    t3: transitions.CANCEL,
    t4: transitions.CANCEL_BY_CUSTOMER_AFTER_PROVIDER_SINGED,
    t5: transitions.CANCEL_BY_PROVIDER_AFTER_PROVIDER_SINGED,
    t6: transitions.CANCEL_BY_CUSTOMER_AFTER_CUSTOMER_SINGED,
    t7: transitions.CANCEL_BY_PROVIDER_AFTER_CUSTOMER_SINGED,
  },
  [states.PROVIDER_SIGNED]: {
    t1: transitions.SIGNED_BY_PROVIDER,
  },
  [states.CUSTOMER_SIGNED]: {
    t1: transitions.SIGNED_BY_CUSTOMER,
  },
  [states.DELIVERED]: {
    t1: transitions.COMPLETE,
    t2: transitions.OPERATOR_COMPLETE,
  },
  [states.BOOKING_EXTENDED]: {
    t1: transitions.EXTEND_BOOKING,
  },
  [states.REFUND]: {
    t1: transitions.REFUND_SECURITY_DEPOSIT,
    t2: transitions.DECLINE_DAMAGE_CLAIM_FULL_REFUND,
    t2: transitions.ACCEPT_DAMAGE_CLAIM,
    t2: transitions.AUTO_REFUND_FULL_SECURITY_DEPOSIT,
  },
  [states.REFUND_REQUESTED]: {
    t1: transitions.REQUEST_DAMAGE_CLAIM,
  },
  [states.REVIEWED]: {
    t1: transitions.REVIEW_2_BY_PROVIDER,
    t2: transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    t3: transitions.REVIEW_2_BY_CUSTOMER,
    t4: transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  },
};
