import { claimListing } from '../util/api';
import { getProviderCommission } from '../util/dataExtractors';

export const CLAIM_LISTING_REQUEST = 'app/global/CLAIM_LISTING_REQUEST';
export const CLAIM_LISTING_SUCCESS = 'app/global/CLAIM_LISTING_SUCCESS';
export const CLAIM_LISTING_ERROR = 'app/global/CLAIM_LISTING_ERROR';

export const FETCH_COMMISSION_REQUEST = 'app/global/FETCH_COMMISSION_REQUEST';
export const FETCH_COMMISSION_SUCCESS = 'app/global/FETCH_COMMISSION_SUCCESS';
export const FETCH_COMMISSION_ERROR = 'app/global/FETCH_COMMISSION_ERROR';

// ================ Reducer ================ //

const initialState = {
  claimListingInProgress: false,
  claimListingSuccess: null,
  claimListingError: null,
  fetchCommissionInProgress: false,
  providerCommissionPercentage: 0,
  fetchCommissionError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLAIM_LISTING_REQUEST:
      return {
        ...state,
        claimListingInProgress: true,
        claimListingError: null,
      };
    case CLAIM_LISTING_SUCCESS:
      return {
        ...state,
        claimListingInProgress: false,
        claimListingError: null,
        claimListingSuccess: true,
      };
    case CLAIM_LISTING_ERROR:
      return {
        ...state,
        claimListingInProgress: true,
        claimListingError: payload,
      };
    case FETCH_COMMISSION_REQUEST:
      return {
        ...state,
        fetchCommissionInProgress: true,
        fetchCommissionError: null,
      };
    case FETCH_COMMISSION_SUCCESS:
      return {
        ...state,
        providerCommissionPercentage: payload,
        fetchCommissionInProgress: false,
        fetchCommissionError: null,
      };
    case FETCH_COMMISSION_ERROR:
      return {
        ...state,
        fetchCommissionInProgress: true,
        fetchCommissionError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const claimListingSelectors = state => {
  return {
    claimListingInProgress: state.global.claimListingInProgress,
    claimListingSuccess: state.global.claimListingSuccess,
    claimListingError: state.global.claimListingError,
    providerCommissionPercentage: state.global.providerCommissionPercentage,
  };
};

// ================ Action creators ================ //

export const claimListingRequest = () => ({
  type: CLAIM_LISTING_REQUEST,
});

export const claimListingSuccess = () => ({
  type: CLAIM_LISTING_SUCCESS,
});

export const claimListingError = error => ({
  type: CLAIM_LISTING_ERROR,
  payload: error,
  error: true,
});

export const fetchCommissionRequest = () => ({
  type: FETCH_COMMISSION_REQUEST,
});

export const fetchCommissionSuccess = payload => ({
  type: FETCH_COMMISSION_SUCCESS,
  payload,
});

export const fetchCommissionError = error => ({
  type: FETCH_COMMISSION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const claimInfoListing = params => {
  return async (dispatch, getState, sdk) => {
    dispatch(claimListingRequest());

    try {
      const response = await claimListing({ ...params });
      dispatch(claimListingSuccess());
      return response;
    } catch (error) {
      dispatch(claimListingError(error));
    }
  };
};

export const fetchCommissions = params => {
  return async (dispatch, getState, sdk) => {
    dispatch(fetchCommissionRequest());
    try {
      const response = await sdk.assetsByAlias({
        paths: ['transactions/commission.json'],
        alias: 'latest',
      });
      const providerCommissinPercentage = getProviderCommission(response);
      dispatch(fetchCommissionSuccess(providerCommissinPercentage?.percentage));
      return response;
    } catch (error) {
      dispatch(fetchCommissionError(error));
    }
  };
};
