import React, { useEffect } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { isMainSearchTypeKeywords } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { Heading, Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import SearchForm from './SearchForm/SearchForm';
import css from './NotFoundPage.module.css';

const NotFoundPage = ({ scrollingDisabled }) => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const intl = useIntl();

  const title = intl.formatMessage({ id: 'NotFoundPage.title' });

  const handleSearchSubmit = values => {
    const { keywords, location } = values;
    const { search, selectedPlace } = location || {};
    const { origin, bounds } = selectedPlace || {};
    const searchParams = keywords ? { keywords } : { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  useEffect(() => {
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
  }, []);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.root}>
          <div className={css.content}>
            <div className={css.number}>404</div>
            <Heading as="h1" rootClassName={css.heading}>
              <FormattedMessage id="NotFoundPage.heading" />
            </Heading>
            <p className={css.description}>
              <FormattedMessage
                id="NotFoundPage.description"
                values={{ marketplaceName: config.marketplaceName }}
              />
            </p>
            <SearchForm
              className={css.searchForm}
              isKeywordSearch={isMainSearchTypeKeywords(config)}
              onSubmit={handleSearchSubmit}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

NotFoundPage.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => ({
  scrollingDisabled: isScrollingDisabled(state),
});

export default connect(mapStateToProps)(NotFoundPage);
