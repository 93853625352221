import React from 'react';
import { Helmet } from 'react-helmet-async';

const MAPBOX_SCRIPT_ID = 'mapbox_GL_JS';
const GOOGLE_MAPS_SCRIPT_ID = 'GoogleMapsApi';

/**
 * Include scripts (like Map Provider).
 * These scripts are relevant for whole application: location search in Topbar and maps on different pages.
 * However, if you don't need location search and maps, you can just omit this component from app.js
 *
 * Note: When adding new external scripts/styles/fonts/etc.,
 *         if a Content Security Policy (CSP) is turned on, the new URLs
 *         should be whitelisted in the policy. Check: server/csp.js
 */
export const IncludeScripts = props => {
  const { marketplaceRootURL: rootURL, maps, analytics, schemaArrayJSONString } =
    props?.config || {};
  const { googleAnalyticsId, plausibleDomains } = analytics || {};
  const { mapProvider, googleMapsAPIKey, mapboxAccessToken } = maps || {};
  const isGoogleMapsInUse = mapProvider === 'googleMaps';
  const isMapboxInUse = mapProvider === 'mapbox';

  // Add Google Analytics script if correct id exists (it should start with 'G-' prefix)
  const hasGoogleAnalyticsv4Id = googleAnalyticsId?.indexOf('G-') === 0;

  // Collect relevant map libraries and analytics libraries
  let mapLibraries = [];
  let analyticsLibraries = [];

  if (isMapboxInUse) {
    // Include Mapbox SDK from our static folder
    mapLibraries.push(
      <script
        key="mapboxSDK"
        src={`${rootURL}/static/scripts/mapbox/mapbox-sdk.min.js`}
      ></script>
    );
    // Add Mapbox CSS
    mapLibraries.push(
      <link
        key="mapbox_GL_CSS"
        href="https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css"
        rel="stylesheet"
        crossOrigin
      />
    );
    // Only include the Mapbox script if it isn't already present (client-side only)
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser || !document.getElementById(MAPBOX_SCRIPT_ID)) {
      mapLibraries.push(
        <script
          id={MAPBOX_SCRIPT_ID}
          key="mapbox_GL_JS"
          src="https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js"
          crossOrigin
        ></script>
      );
    }
  } else if (isGoogleMapsInUse) {
    // Only include the Google Maps API script if it isn't already present
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser || !document.getElementById(GOOGLE_MAPS_SCRIPT_ID)) {
      mapLibraries.push(
        <script
          id={GOOGLE_MAPS_SCRIPT_ID}
          key="GoogleMapsApi"
          src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`}
          crossOrigin
        ></script>
      );
    }
  }

  if (googleAnalyticsId && hasGoogleAnalyticsv4Id) {
    analyticsLibraries.push(
      <script
        key="gtag.js"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        crossOrigin
      ></script>
    );

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        dataLayer.push(arguments);
      };
      gtag('js', new Date());
      gtag('config', googleAnalyticsId, {
        cookie_flags: 'SameSite=None;Secure',
      });
    }
  }

  if (plausibleDomains) {
    analyticsLibraries.push(
      <script
        key="plausible"
        defer
        src="https://plausible.io/js/script.js"
        data-domain={plausibleDomains}
        crossOrigin
      ></script>
    );
  }

  // Additional Scripts
  const extraScripts = [
    // Hotjar Analytics Script
    (
      <script
        key="hotjar"
        defer
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:5099035, hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script'); r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
    ),
    // Facebook Pixel Code
    (
      <script
        key="facebook-pixel"
        defer
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s){
              if(f.fbq)return;
              n=f.fbq=function(){
                n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)
              };
              if(!f._fbq) f._fbq=n;
              n.push=n;
              n.loaded=!0;
              n.version='2.0';
              n.queue=[];
              t=b.createElement(e);
              t.async=!0;
              t.src=v;
              s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s);
            }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '885051543236593');
            fbq('track', 'PageView');
          `,
        }}
      />
    ),
    (
      <noscript key="facebook-pixel-noscript">
        {'<img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=885051543236593&ev=PageView&noscript=1" alt="Facebook Pixel" />'}
      </noscript>
    ),
    // Google Analytics (specific version)
    (
      <script
        key="gtag-async"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-DT9G33183H"
        crossOrigin
      />
    ),
    (
      <script
        key="gtag-inline"
        defer
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-DT9G33183H');
          `,
        }}
      />
    ),
    // JSON-LD Schema Script
    schemaArrayJSONString && (
      <script
        key="page-schema"
        id="page-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: schemaArrayJSONString.replace(/</g, '\\u003c'),
        }}
      />
    ),
    // sa-dynamic-optimization Script
    (
      <script
        key="sa-dynamic-optimization"
        nowprocket
        nitro-exclude
        type="text/javascript"
        id="sa-dynamic-optimization"
        data-uuid="25d7c709-8240-449e-8472-f01fa8d4af9e"
        src="data:text/javascript;base64,dmFyIHNjcmlwdCA9IGRvY3VtZW50LmNyZWF0ZUVsZW1lbnQoInNjcmlwdCIpO3NjcmlwdC5zZXRBdHRyaWJ1dGUoIm5vd3Byb2NrZXQiLCAiIik7c2NyaXB0LnNldEF0dHJpYnV0ZSgibml0cm8tZXhjbHVkZSIsICIiKTtzY3JpcHQuc3JjID0gImh0dHBzOi8vZGFzaGJvYXJkLnNlYXJjaGF0bGFzLmNvbS9zY3JpcHRzL2R5bmFtaWNfb3B0aW1pemF0aW9uLmpzIjtzY3JpcHQuZGF0YXNldC51dWlkID0gIjI1ZDdjNzA5LTgyNDAtNDQ5ZS04NDcyLWYwMWZhOGQ0YWY5ZSI7c2NyaXB0LmlkID0gInNhLWR5bmFtaWMtb3B0aW1pemF0aW9uLWxvYWRlciI7ZG9jdW1lbnQuaGVhZC5hcHBlbmRDaGlsZChzY3JpcHQpOw=="
      ></script>
    ),
  ];

  // If Mapbox is loaded, set the access token
  const isBrowser = typeof window !== 'undefined';
  const isMapboxLoaded = isBrowser && window.mapboxgl;
  if (isMapboxInUse && isMapboxLoaded && !window.mapboxgl.accessToken) {
    window.mapboxgl.accessToken = mapboxAccessToken;
  }

  const onMapLibLoaded = () => {
    if (isMapboxInUse && !window.mapboxgl.accessToken) {
      window.mapboxgl.accessToken = mapboxAccessToken;
    }
  };

  // React Helmet Async doesn't support onLoad prop for scripts.
  // Use onChangeClientState to add load event listeners to our map libraries.
  const onChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(s =>
        [MAPBOX_SCRIPT_ID, GOOGLE_MAPS_SCRIPT_ID].includes(s.id)
      );
      if (foundScript) {
        foundScript.addEventListener('load', onMapLibLoaded, { once: true });
      }
    }
  };

  const allScripts = [
    ...analyticsLibraries,
    ...mapLibraries,
    ...extraScripts.filter(Boolean),
  ];

  return <Helmet onChangeClientState={onChangeClientState}>{allScripts}</Helmet>;
};
