import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_SECURITY_FEE, propTypes } from '../../util/types';
import { isArrayLength } from '../../util/genericHelpers';
import { transitions } from '../../transactions/transactionProcessBooking';

import css from './OrderBreakdown.module.css';
import { getSecurityRefundedAmount } from '../../util/dataExtractors';

const LineItemSecurityDepositPriceRefundMaybe = props => {
  const { transaction, lineItems, txPayoutTotalMaybe, isProvider } = props;

  // Check if the transaction has been refunded
  const isTransactionRefunded = isArrayLength(transaction?.attributes?.transitions)
    ? transaction.attributes.transitions?.find(
        t =>
          t?.transition === transitions.REFUND_SECURITY_DEPOSIT ||
          t?.transition === transitions.ACCEPT_DAMAGE_CLAIM ||
          t?.transition === transitions.DECLINE_DAMAGE_CLAIM_FULL_REFUND ||
          t?.transition === transitions.AUTO_REFUND_FULL_SECURITY_DEPOSIT
      )
    : null;

  if (!isTransactionRefunded) {
    return null;
  }
  // Extract security deposit line item
  // const securityLineItemsItem = lineItems.find(
  //   item => item.code === LINE_ITEM_SECURITY_FEE && !item.reversal
  // )?.lineTotal;
  const securityAmountAfterRefund = getSecurityRefundedAmount(transaction);
  // Parse the total payout value
  const totalPayout = txPayoutTotalMaybe ? parseFloat(txPayoutTotalMaybe.replace('$', '')) : 0;

  // Calculate the adjusted total
  const adjustedTotal = totalPayout - (securityAmountAfterRefund || 0) / 100;

  return securityAmountAfterRefund && !isProvider ? (
    <>
      <div className={css.lineItem}>
        <div className={css.iteminfo}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.securityDepositFeeRefund" />{' '}
          </span>
          <span className={css.itemValue}>-${(securityAmountAfterRefund / 100).toFixed(2)}</span>
        </div>
      </div>
      <div className={css.lineItem}>
        <div className={css.iteminfo}>
          <span className={css.itemLabel}>
            <FormattedMessage id={'OrderBreakdown.totalPaid'} />{' '}
          </span>
          <span className={css.itemValue}>${adjustedTotal.toFixed(2)}</span>
        </div>
      </div>
    </>
  ) : null;
};

LineItemSecurityDepositPriceRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSecurityDepositPriceRefundMaybe;
