let ReactPixel = null;

const options = {
  autoConfig: true,
  debug: false,
};

export const initFacebookPixel = async (pixelId) => {
  if (typeof window !== 'undefined' && pixelId) {
    if (!ReactPixel) {
      const module = await import('react-facebook-pixel');
      ReactPixel = module.default;
    }

    ReactPixel.init(pixelId, {}, options);
    ReactPixel.pageView();
  }
};

export const trackFacebookEvent = async (eventName, data = {}) => {
  if (typeof window !== 'undefined') {
    if (!ReactPixel) {
      const module = await import('react-facebook-pixel');
      ReactPixel = module.default;
    }

    ReactPixel.track(eventName, data);
  }
};
