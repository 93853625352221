import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SECURITY_FEE, propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import { calculateTotalProviderCommission } from '../../util/genericHelpers';

import css from './OrderBreakdown.module.css';
import { getSecurityRefundedAmount } from '../../util/dataExtractors';

const { Money } = sdkTypes;

const LineItemCustomTotalPrice = props => {
  const {
    transaction,
    isProvider,
    intl,
    marketplaceCurrency,
    extendedBookingTotalAmount,
    bookingDetails,
    lineItems,
    txPayoutTotalMaybe,
    setTxPayoutTotalMaybe,
  } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const securityLineItemsItem = lineItems.find(
    item => item.code === LINE_ITEM_SECURITY_FEE && !item.reversal
  )?.lineTotal;
  const securityAmountAfterRefund = getSecurityRefundedAmount(transaction);
  const finalSecurityAmount =
    securityAmountAfterRefund && securityAmountAfterRefund > 0
      ? securityAmountAfterRefund
      : securityLineItemsItem?.amount;
  const securityDepositAmount = isProvider && finalSecurityAmount ? finalSecurityAmount : 0;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const totalCommission = calculateTotalProviderCommission(bookingDetails);

  const extendedBookingTotal = isProvider
    ? totalCommission + extendedBookingTotalAmount + totalPrice?.amount - securityDepositAmount
    : extendedBookingTotalAmount + totalPrice?.amount;
  const formattedFinalPrice = extendedBookingTotal
    ? formatMoney(intl, new Money(extendedBookingTotal, marketplaceCurrency))
    : formattedTotalPrice;

  useEffect(() => {
    if (!txPayoutTotalMaybe) {
      setTxPayoutTotalMaybe(formattedFinalPrice);
    }
  }, [txPayoutTotalMaybe, formattedFinalPrice]);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalProfit';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  } else if (finalSecurityAmount > 0) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedFinalPrice}</div>
      </div>
    </>
  );
};

LineItemCustomTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomTotalPrice;
