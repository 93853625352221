import React from 'react';
import Decimal from 'decimal.js';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Helper function to calculate the total price in subunits for multiple line items
export const calculateLineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce(
    (total, item) => total.plus(item.lineTotal.amount),
    new Decimal(0)
  );
  const currency = lineItems.length > 0 ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

// Helper function to check if a line item represents commission
const isCommissionLineItem = lineItem =>
  lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
  lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION;

// Helper function to filter non-commission, reversal line items
const getNonCommissionReversalLineItems = lineItems =>
  lineItems.filter(item => !isCommissionLineItem(item) && item.reversal);

// Helper function to filter non-commission, non-reversal line items
export const getNonCommissionNonReversalLineItems = lineItems =>
  lineItems.filter(item => !isCommissionLineItem(item) && !item.reversal);

const LineItemRefundMaybe = ({ lineItems, intl, marketplaceCurrency, refundPercentage }) => {
  const refundLineItems = getNonCommissionReversalLineItems(lineItems);
  const baseLineItems = getNonCommissionNonReversalLineItems(lineItems);

  const refund = calculateLineItemsTotal(refundLineItems, marketplaceCurrency);
  const basePriceObject = calculateLineItemsTotal(baseLineItems, marketplaceCurrency);

  const basePrice = basePriceObject.amount ? parseFloat(basePriceObject.amount) : null;
  const refundAmount = refundPercentage ? (basePrice * refundPercentage) / 100 : basePrice;
  const finalPrice = basePrice && refundAmount && new Money(refundAmount, marketplaceCurrency);

  const formattedRefund = refundLineItems.length > 0 ? formatMoney(intl, refund) : null;
  const formattedFinalPrice = finalPrice ? formatMoney(intl, finalPrice) : null;

  return (
    formattedFinalPrice &&
    formattedRefund && (
      <div className={css.lineItem}>
        <div className={css.iteminfo}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.refund" />
          </span>
          <span className={css.itemValue}>
            {formattedFinalPrice ? `-${formattedFinalPrice}` : null}
          </span>
        </div>
      </div>
    )
  );
};

LineItemRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
